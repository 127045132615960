import React, { useEffect, useState } from 'react'
import { ClientService } from '../../component/services/client.service';
import toast from 'react-hot-toast';
import { Card, Col, Image, Row, Table, Form, OverlayTrigger, Tooltip, Badge, Spinner } from 'react-bootstrap';
import TablePaginationComponent from '../../component/table/TablePagination';
import { resolveModuleName } from 'typescript';
import { FaFileImage, FaFilePdf, FaRegEye } from 'react-icons/fa';
import PreviewModal from '../../component/modals/Preview.modal';
import moment from 'moment';
import Select from "react-select"
import { StringHelper } from '../../component/helpers/String.helper';
import { Label } from 'recharts';

export default function BypassDetails() {

    const [shifts, setShifts] = useState<any>();
    const [selectValue, setSelectValue] = useState<any>();
    const [pageSize, setPageSize] = useState<number>(10);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [totalCount, setTotolCount] = useState<number>(0);

    const [showPreviewModal, setShowPreviewModal] = useState<any>(undefined)
    const [bypassData, setBypassData] = useState<any[]>([]);
    const [centers, setCenters] = useState<any>();
    const [selectedCenters, setSelectedCenters] = useState<any>();

    const [zones, setZones] = useState<any>();
    const [districts, setDistricts] = useState<any>();
    const [states, setStates] = useState<any>();


    const [selectedZones, setSelectedZones] = useState<any>();
    const [selectedDistricts, setSelectedDistricts] = useState<any>();
    const [selectedStates, setSelectedStates] = useState<any>();

    const [shiftDate, setShiftDate] = useState<any>();
    const [selectedShiftDate, setSelectedShiftDate] = useState<any>()

    const [verificationEnums, setVerificationEnums] = useState<any>();
    const [selectedVerification, setSelectedVerification] = useState<any>();

    const [loading, setLoading] = useState<boolean>(false)



    const getVerificationEnums = async () => {
        await ClientService.getVerificationEnums().then(res => {
            if (res.status === 200) {
                // console.log(res.data, "enums")
                setVerificationEnums(Object.values(res.data.enums).map((data: any) => {
                    return {
                        label: data === 'fingerprint' ? "Right Fingerprint" : data === "fingerprint2" ? "Left Fingerprint" : data,
                        value: data
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }


    const getAllZones = async () => {
        await ClientService.getUniqueZone(selectValue).then(res => {
            if (res.status === 200) {
                setZones(res.data.map((zone: any) => {
                    return {
                        value: zone,
                        label: zone
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }


    const getUniqueCenter = async () => {
        let params = new URLSearchParams();
        selectedZones.forEach((zone: any) => params.append("zone[]", zone?.value));
        selectedStates.forEach((state: any) => params.append("state[]", state?.value));
        selectedDistricts.forEach((district: any) => params.append("district[]", district?.value));

        await ClientService.getCenters(selectValue, "?" + params.toString()).then(res => {
            if (res.status === 200) {
                setCenters(res.data?.centers?.map((zone: any) => {
                    return {
                        value: zone,
                        label: zone
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }


    const getUniqueState = async () => {
        const params = new URLSearchParams();
        selectedZones.forEach((zone: any) => params.append("zone[]", zone?.value));
        await ClientService.getStates(selectValue, "?" + params.toString()).then(res => {
            if (res.status === 200) {
                setStates(res.data.states?.map((zone: any) => {
                    return {
                        value: zone,
                        label: zone
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }


    const getUniqueCity = async () => {
        let params = new URLSearchParams();
        selectedZones.forEach((zone: any) => params.append("zone[]", zone?.value));
        selectedStates.forEach((state: any) => params.append("state[]", state?.value));
        await ClientService.getCities(selectValue, "?" + params.toString()).then(res => {
            if (res.status === 200) {
                setDistricts(res.data?.districts?.map((zone: any) => {
                    return {
                        value: zone,
                        label: zone
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }



    const getAllShift = async () => {
        await ClientService.getAllShifts().then(res => {
            if (res.status === 200) {
                setShifts(res.data.shifts.map((data: any) => {
                    return {
                        label: data.shiftName,
                        value: data._id
                    }
                }))
                setShiftDate(res.data.shifts.map((data: any) => {
                    return {
                        label: moment(data.startTime).format("DD MMM YYYY"),
                        value: data._id
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }


    const getAllBypassData = async () => {
        let payload = {};
        if (selectedCenters?.length > 0) {
            payload = { ...payload, center: selectedCenters?.map((center: any) => center.value) }
        }
        if (selectedStates?.length > 0) {
            payload = { ...payload, state: selectedStates?.map((state: any) => state.value) }
        }
        if (selectedDistricts?.length > 0) {
            payload = { ...payload, city: selectedDistricts?.map((district: any) => district.value) }
        }
        if (selectedZones?.length > 0) {
            payload = { ...payload, zone: selectedZones?.map((zone: any) => zone.value) }
        }

        setLoading(true)
        await ClientService.getAllBypassData(selectValue, pageSize, pageNumber, payload)
            .then((res) => {
                if (res.status === 200) {
                    setBypassData(res.data.bypassVerifications);
                    setTotolCount(res.data.totalBypassVerifications);
                }
            })
            .catch((err) => {
                toast.error(err.response?.data || "An error occurred");
            });
        setLoading(false)
    };


    useEffect(() => {
        if (selectValue) {
            getAllZones();
            getVerificationEnums();
        }
    }, [selectValue]);

    useEffect(() => {
        if (selectedZones?.length > 0) {
            getUniqueState();
        } else {
            setStates([]);
            setSelectedStates([]);
        }
    }, [selectedZones]);

    useEffect(() => {
        if (selectedStates?.length > 0 && selectedZones) {
            getUniqueCity();
        } else {
            setDistricts([]);
            setSelectedDistricts([]);
        }
    }, [selectedStates]);

    useEffect(() => {
        if (selectedStates && selectedZones && selectedDistricts?.length > 0) {
            getUniqueCenter();
        } else {
            setCenters([]);
            setSelectedCenters([]);
        }
    }, [selectedDistricts]);


    useEffect(() => {
        if (selectValue) {
            getAllBypassData();
        }
    }, [selectValue, pageNumber, pageSize, selectedCenters, selectedStates, selectedDistricts, selectedZones, selectedVerification]);


    useEffect(() => {
        getAllShift();
    }, [])

    return (
        <>
            {/* <div className="d-flex justify-content-between align-items-center mt-3">
                <h4 className="d-flex text-secondary mt-3">Bypass Candidates</h4>
                <div className="d-flex justify-content-start gap-3">
                    <Select
                        options={shifts}
                        onChange={(e: any) => setSelectValue(e.value)}
                        placeholder="Select shift"
                    />
                </div>
            </div> */}
            <div className="d-flex justify-content-between align-items-center mt-5">
                <h4 className="d-flex text-secondary">Bypass Candidates</h4>
                <div className="d-flex">
                    <Select
                        options={shiftDate}
                        onChange={(e: any) => setSelectedShiftDate(e)}
                        placeholder="Select shift Date"
                        isClearable
                    />
                    <Select
                        options={
                            selectedShiftDate
                                ? shifts?.filter((shift: any) => shift.value === selectedShiftDate.value)
                                : []
                        }
                        onChange={(e: any) => setSelectValue(e?.value)}
                        placeholder="Select shift"
                        className="ms-3"
                        isClearable
                    />
                </div>
            </div>
            <Card
                className="mt-3"
                style={{
                    backgroundColor: "#f9f9f9",
                    borderRadius: "12px",
                    border: "none",
                }}
            >
                <Card.Body>
                    <Row>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label className="text-muted fw-bold">
                                    Divisions
                                </Form.Label>
                                <Select
                                    options={[{ label: "All", value: "All" }, ...(zones?.map((state: any) => ({ label: state?.label, value: state?.value })) || [])]}
                                    onChange={(selectedOptions: any) => {
                                        if (selectedOptions?.some((option: any) => option.value === "All")) {
                                            setSelectedZones(
                                                zones?.map((state: any) => ({
                                                    label: state?.label,
                                                    value: state?.value,
                                                })) || []
                                            );
                                        } else {
                                            setSelectedZones(selectedOptions || []);
                                        }
                                    }}
                                    value={selectedZones}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    isClearable
                                    isMulti
                                />
                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label className="text-muted fw-bold">
                                    States
                                </Form.Label>
                                <Select
                                    options={[{ label: "All", value: "All" }, ...(states?.map((state: any) => ({ label: state?.label, value: state?.value })) || [])]}
                                    onChange={(selectedOptions: any) => {
                                        if (selectedOptions?.some((option: any) => option.value === "All")) {
                                            setSelectedStates(
                                                states?.map((state: any) => ({
                                                    label: state?.label,
                                                    value: state?.value,
                                                })) || []
                                            );
                                        } else {
                                            setSelectedStates(selectedOptions || []);
                                        }
                                    }}
                                    value={selectedStates}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    isClearable
                                    isMulti
                                />

                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label className="text-muted fw-bold">
                                    City
                                </Form.Label>
                                <Select
                                    options={[{ label: "All", value: "All" }, ...(districts?.map((state: any) => ({ label: state?.label, value: state?.value })) || [])]}
                                    onChange={(selectedOptions: any) => {
                                        if (selectedOptions?.some((option: any) => option.value === "All")) {
                                            setSelectedDistricts(
                                                districts?.map((state: any) => ({
                                                    label: state?.label,
                                                    value: state?.value,
                                                })) || []
                                            );
                                        } else {
                                            setSelectedDistricts(selectedOptions || []);
                                        }
                                    }}
                                    value={selectedDistricts}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    isClearable
                                    isMulti
                                />
                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label className="text-muted fw-bold">
                                    Centers
                                </Form.Label>
                                <Select
                                    options={[{ label: "All", value: "All" }, ...(centers?.map((state: any) => ({ label: state?.label, value: state?.value })) || [])]}
                                    onChange={(selectedOptions: any) => {
                                        if (selectedOptions?.some((option: any) => option.value === "All")) {
                                            setSelectedCenters(
                                                centers?.map((state: any) => ({
                                                    label: state?.label,
                                                    value: state?.value,
                                                })) || []
                                            );
                                        } else {
                                            setSelectedCenters(selectedOptions || []);
                                        }
                                    }}
                                    value={selectedCenters}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    isClearable
                                    isMulti
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label className="text-muted fw-bold">
                                    Verification Type
                                </Form.Label>
                                <Select
                                    options={verificationEnums}
                                    onChange={setSelectedVerification}
                                    value={selectedVerification}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    isMulti
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            {loading &&
                <div className="d-flex justify-content-center align-items-center my-3">
                    <Spinner animation="border" />
                </div>
            }
            <Row>
                <Col>
                    <Card
                        className="mt-3"
                        style={{
                            backgroundColor: "#f9f9f9",
                            borderRadius: "12px",
                            border: "none",
                        }}
                    >
                        <Card.Body>
                            <Table striped hover responsive>
                                <thead>
                                    <tr>
                                        <th style={{ fontSize: 14 }}>Sr. No</th>
                                        <th style={{ fontSize: 14 }}>Name</th>
                                        <th style={{ fontSize: 14 }}>Reg. No</th>
                                        <th style={{ fontSize: 14 }}>State</th>
                                        <th style={{ fontSize: 14 }}>City</th>
                                        <th style={{ fontSize: 14 }}>Division</th>
                                        <th style={{ fontSize: 14 }}>Center</th>
                                        <th style={{ fontSize: 14 }}>Verification Method</th>
                                        {/* <th style={{ fontSize: 14 }}>B. Type</th> */}
                                        <th style={{ fontSize: 14 }}>B. Sub-type</th>
                                        <th style={{ fontSize: 14 }}>B. Reason</th>
                                        <th style={{ fontSize: 14 }}>B. Image</th>
                                        <th style={{ fontSize: 14 }}>Live Photo</th>
                                        <th style={{ fontSize: 14 }}>Bypass On</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {bypassData && bypassData.length > 0 ? (
                                        bypassData.map((data: any, index: number) => {
                                            // console.log(data, "bypass")
                                            return (
                                                <tr key={data._id || index}>
                                                    <td style={{ fontSize: 13 }}>{index + 1}</td>
                                                    <td style={{ fontSize: 13 }}>{data?.studentId?.name || "N/A"}</td>
                                                    <td style={{ fontSize: 13 }}>{data?.studentId?.regNo || "N/A"}</td>
                                                    <td style={{ fontSize: 13 }}>{data?.studentId?.state || "N/A"}</td>
                                                    <td style={{ fontSize: 13 }}>{data?.studentId?.city || "N/A"}</td>
                                                    <td style={{ fontSize: 13 }}>{data?.studentId?.zone || "N/A"}</td>
                                                    <td style={{ fontSize: 13 }}>{data?.studentId?.centerPreference || "N/A"}</td>
                                                    {/* <td style={{ fontSize: 13 }}>{data?.verificationDetails && data?.verificationDetails?.map((data: any) => {
                                                        return (
                                                            <Badge>{data?.bypassType === "fingerprint" ? "Right Fingerprint" : data?.bypassType === "fingerprint2" ? "Left Fingerprint" : data?.bypassType}</Badge>
                                                        )
                                                    }) || "--"}</td> */}
                                                    <td style={{ fontSize: 13 }}>
                                                        {data?.verificationDetails && data?.verificationDetails.map((detail: any, index: number) => (
                                                            <Badge className='me-2' bg={detail?.method?.toLowerCase() !== detail?.bypassType?.toLowerCase() ? "success" : "danger"} >{detail?.method === "fingerprint" ? "Right Fingerprint" : detail?.method === "fingerprint2" ? "Left Fingerprint" : detail?.method}</Badge>
                                                        ))}
                                                    </td>
                                                    <td style={{ fontSize: 13 }}>{data?.verificationDetails && data?.verificationDetails[0]?.bypassSubType || "--"}</td>
                                                    <td style={{ fontSize: 13, position: "relative" }}>
                                                        <span>
                                                            {data?.verificationDetails?.[0]?.bypassReason
                                                                ? StringHelper.truncate(data.verificationDetails[0].bypassReason, 15)
                                                                : "--"}
                                                        </span>
                                                        {data?.verificationDetails?.[0]?.bypassReason &&
                                                            data.verificationDetails[0].bypassReason.length > 15 && (
                                                                <OverlayTrigger
                                                                    placement="right"
                                                                    delay={{ show: 250, hide: 400 }}
                                                                    overlay={
                                                                        <Tooltip id="button-tooltip">
                                                                            <span>{data.verificationDetails[0].bypassReason || "--"}</span>
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <span style={{ marginLeft: "8px", cursor: "pointer" }}>
                                                                        <FaRegEye />
                                                                    </span>
                                                                </OverlayTrigger>
                                                            )}
                                                    </td>


                                                    <td className="" style={{ fontSize: 13 }}>
                                                        <div className="d-flex align-items-center gap-3">
                                                            {data?.verificationDetails && data?.verificationDetails[0]?.bypassImage ? (
                                                                <div style={{ width: 50, height: 50 }}>
                                                                    <Image
                                                                        src={data?.verificationDetails[0]?.bypassImage}
                                                                        width="100%"
                                                                        height="100%"
                                                                        style={{ borderRadius: '50%' }}
                                                                        onClick={() => setShowPreviewModal(data?.verificationDetails[0]?.bypassImage)}
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <span>No Image</span>
                                                            )}
                                                            {data?.verificationDetails && data?.verificationDetails[0]?.bypassImage2 ? (
                                                                <div style={{ width: 50, height: 50 }}>
                                                                    <Image
                                                                        src={data?.verificationDetails[0]?.bypassImage2}
                                                                        width="100%"
                                                                        height="100%"
                                                                        style={{ borderRadius: '50%' }}
                                                                        onClick={() => setShowPreviewModal(data?.verificationDetails[0]?.bypassImage2)}
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <span>No Image</span>
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td style={{ fontSize: 13 }}>
                                                        {data?.userLivePhoto && data?.userLivePhoto ? (
                                                            <div style={{ width: 50, height: 50 }}>
                                                                <Image
                                                                    src={data?.userLivePhoto}
                                                                    width="100%"
                                                                    height="100%"
                                                                    style={{ borderRadius: '50%' }}
                                                                    onClick={() => setShowPreviewModal(data?.userLivePhoto)}

                                                                />
                                                            </div>
                                                        ) : (
                                                            <span>No Image</span>
                                                        )}
                                                    </td>
                                                    <td style={{ fontSize: 13 }}>{data?.verificationDetails && moment(data?.verificationDetails[0]?.success_time).format("DD/MM/YYYY, HH:MM A") || "--"}</td>
                                                </tr>
                                            )
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan={14} className="text-center">No data available</td>
                                        </tr>
                                    )}
                                </tbody>

                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col>
                    <TablePaginationComponent
                        currentPage={pageNumber}
                        itemsCount={totalCount}
                        itemsPerPage={pageSize}
                        setItemsPerPage={(e: any) => setPageSize(e)}
                        setCurrentPage={setPageNumber}
                    />
                </Col>
            </Row>
            <PreviewModal
                show={showPreviewModal}
                handleClose={() => setShowPreviewModal(undefined)}

            />
        </>
    )
}
