import React, { useEffect, useState } from 'react'
import Select from "react-select"
import { ClientService } from '../../component/services/client.service';
import toast from 'react-hot-toast';
import { Card, Col, Form, Row, Table } from 'react-bootstrap';
import TablePaginationComponent from '../../component/table/TablePagination';
import { resolveModuleName } from 'typescript';
import { FaFileImage, FaFilePdf } from 'react-icons/fa';
import PreviewModal from '../../component/modals/Preview.modal';
import moment from 'moment';

export default function CSRReport() {

    const [shifts, setShifts] = useState<any>();
    const [selectValue, setSelectValue] = useState<any>();
    const [pageSize, setPageSize] = useState<number>(10);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [totalCount, setTotolCount] = useState<number>(0);
    const [csrData, setCsrData] = useState<any>();

    const [shiftDate, setShiftDate] = useState<any>();
    const [selectedShiftDate, setSelectedShiftDate] = useState<any>()



    const [showPreviewModal, setShowPreviewModal] = useState<any>(undefined)

    const [centers, setCenters] = useState<any>();
    const [selectedCenters, setSelectedCenters] = useState<any>();

    const [zones, setZones] = useState<any>();
    const [districts, setDistricts] = useState<any>();
    const [states, setStates] = useState<any>();


    const [selectedZones, setSelectedZones] = useState<any>();
    const [selectedDistricts, setSelectedDistricts] = useState<any>();
    const [selectedStates, setSelectedStates] = useState<any>();

    const getAllZones = async () => {
        await ClientService.getUniqueZone(selectValue).then(res => {
            if (res.status === 200) {
                setZones(res.data.map((zone: any) => {
                    return {
                        value: zone,
                        label: zone
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }


    const getUniqueCenter = async () => {
        await ClientService.getUniqueCenter(selectValue).then(res => {
            if (res.status === 200) {
                setCenters(res.data.map((zone: any) => {
                    return {
                        value: zone,
                        label: zone
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }

    const getUniqueState = async () => {
        await ClientService.getUniqueState(selectValue).then(res => {
            if (res.status === 200) {
                setStates(res.data.map((zone: any) => {
                    return {
                        value: zone,
                        label: zone
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }


    const getUniqueCity = async () => {
        await ClientService.getUniqueCity(selectValue).then(res => {
            if (res.status === 200) {
                setDistricts(res.data.map((zone: any) => {
                    return {
                        value: zone,
                        label: zone
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }


    const getFileTypeFromUrl = (url: any) => {
        const fileExtensions: any = {
            '.pdf': 'pdf',
            '.jpg': 'image',
            '.jpeg': 'image',
            '.png': 'image',
            '.gif': 'image'
        };

        const extension = url.slice(url.lastIndexOf('.')).toLowerCase();

        return fileExtensions[extension] || 'unknown';
    };

    const getAllShift = async () => {
        await ClientService.getAllShifts().then(res => {
            if (res.status === 200) {
                setShifts(res.data.shifts.map((data: any) => {
                    return {
                        label: data.shiftName,
                        value: data._id
                    }
                }))
                setShiftDate(res.data.shifts.map((data: any) => {
                    return {
                        label: moment(data.startTime).format("DD MMM YYYY"),
                        value: data._id
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }


    const getAllCSRReport = async () => {
        await ClientService.getAllCSRReport(selectValue, pageSize, pageNumber).then((res) => {
            if (res.status === 200) {
                console.log(res.data, "csr data")
                setCsrData(res.data.data.CSRReports);
                setTotolCount(res.data.data.totalCsrReports)
            }
        })
    }

    useEffect(() => {
        if (selectValue) {
            getAllZones();
            getUniqueCenter();
            getUniqueCity();
            getUniqueState();
        }
    }, [selectValue])


    useEffect(() => {
        if (selectValue) {
            getAllCSRReport();
        }
    }, [selectValue, pageNumber])

    useEffect(() => {
        getAllShift();
    }, [])

    return (
        <>
            <div className="d-flex justify-content-between align-items-center mt-5">

                <h4 className="d-flex text-secondary">CSR Report</h4>
                <div className="d-flex">
                    <Select
                        options={shiftDate}
                        onChange={(e: any) => setSelectedShiftDate(e)} 
                        placeholder="Select shift Date"
                        isClearable
                    />
                    <Select
                        options={
                            selectedShiftDate
                                ? shifts?.filter((shift: any) => shift.value === selectedShiftDate.value)
                                : []
                        }
                        onChange={(e: any) => setSelectValue(e?.value)}
                        placeholder="Select shift"
                        className = "ms-3"
                        isClearable
                    />
                </div>
            </div>
            {/* <Card
                className="mt-3"
                style={{
                    backgroundColor: "#f9f9f9",
                    borderRadius: "12px",
                    border: "none",
                }}
            >
                <Card.Body>
                    <Row>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label className="text-muted fw-bold">
                                    Zones
                                </Form.Label>
                                <Select
                                    options={[{ label: "All", value: "All" }, ...(zones?.map((state: any) => ({ label: state?.label, value: state?.value })) || [])]}
                                    onChange={(selectedOptions: any) => {
                                        if (selectedOptions?.some((option: any) => option.value === "All")) {
                                            setSelectedZones(
                                                zones?.map((state: any) => ({
                                                    label: state?.label,
                                                    value: state?.value,
                                                })) || []
                                            );
                                        } else {
                                            setSelectedZones(selectedOptions || []);
                                        }
                                    }}
                                    value={selectedZones}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    isClearable
                                    isMulti
                                />
                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label className="text-muted fw-bold">
                                    States
                                </Form.Label>
                                <Select
                                    options={[{ label: "All", value: "All" }, ...(states?.map((state: any) => ({ label: state?.label, value: state?.value })) || [])]}
                                    onChange={(selectedOptions: any) => {
                                        if (selectedOptions?.some((option: any) => option.value === "All")) {
                                            setSelectedStates(
                                                states?.map((state: any) => ({
                                                    label: state?.label,
                                                    value: state?.value,
                                                })) || []
                                            );
                                        } else {
                                            setSelectedStates(selectedOptions || []);
                                        }
                                    }}
                                    value={selectedStates}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    isClearable
                                    isMulti
                                />

                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label className="text-muted fw-bold">
                                    City
                                </Form.Label>
                                <Select
                                    options={[{ label: "All", value: "All" }, ...(districts?.map((state: any) => ({ label: state?.label, value: state?.value })) || [])]}
                                    onChange={(selectedOptions: any) => {
                                        if (selectedOptions?.some((option: any) => option.value === "All")) {
                                            setSelectedDistricts(
                                                districts?.map((state: any) => ({
                                                    label: state?.label,
                                                    value: state?.value,
                                                })) || []
                                            );
                                        } else {
                                            setSelectedDistricts(selectedOptions || []);
                                        }
                                    }}
                                    value={selectedDistricts}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    isClearable
                                    isMulti
                                />
                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label className="text-muted fw-bold">
                                    Centers
                                </Form.Label>
                                <Select
                                    options={[{ label: "All", value: "All" }, ...(centers?.map((state: any) => ({ label: state?.label, value: state?.value })) || [])]}
                                    onChange={(selectedOptions: any) => {
                                        if (selectedOptions?.some((option: any) => option.value === "All")) {
                                            setSelectedCenters(
                                                centers?.map((state: any) => ({
                                                    label: state?.label,
                                                    value: state?.value,
                                                })) || []
                                            );
                                        } else {
                                            setSelectedCenters(selectedOptions || []);
                                        }
                                    }}
                                    value={selectedCenters}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    isClearable
                                    isMulti
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Card.Body>
            </Card> */}
            <Row>
                <Col>
                    <Card
                        className="mt-3"
                        style={{
                            backgroundColor: "#f9f9f9",
                            borderRadius: "12px",
                            border: "none",
                        }}
                    >
                        <Card.Body>
                            <Table striped hover responsive>
                                <thead>
                                    <tr>
                                        <th>Sr. No</th>
                                        <th>Biometric Operator Username</th>
                                        <th>Center Name</th>
                                        <th>Exam Name</th>
                                        <th>Report</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {csrData?.length > 0  ? csrData?.map((data: any, index: any) => {
                                        return (
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>{data?.invigilatorId?.userName}</td>
                                                <td>{data?.invigilatorId?.centerId[0] || "--"}</td>
                                                <td>{data?.examId?.name}</td>
                                                <td>
                                                    {data?.CSRReport?.map((fileUrl: any, index: number) =>
                                                        getFileTypeFromUrl(fileUrl) === "image" ? (
                                                            <FaFileImage key={index} onClick={() => setShowPreviewModal(fileUrl)} />
                                                        ) : (
                                                            <a href={fileUrl} download={true} className="text-decoration-none">
                                                                <FaFilePdf key={index} />
                                                            </a>
                                                        )
                                                    )}
                                                </td>

                                            </tr>
                                        )
                                    }): "No data found"}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <TablePaginationComponent
                        currentPage={pageNumber}
                        itemsCount={totalCount}
                        itemsPerPage={pageSize}
                        setItemsPerPage={(e: any) => setPageSize(e)}
                        setCurrentPage={setPageNumber}
                    />
                </Col>
            </Row>

            <PreviewModal
                show={showPreviewModal}
                handleClose={() => setShowPreviewModal(undefined)}

            />
        </>
    )
}
