import React from 'react';
import { Button, Container, Image, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import './Topbar.css';
import { GroupedRoutes } from '../routes/routes';
import IRouter, { IGroupedRouter } from '../Interface/IRouter';
import Logo from "../../assests/Logo.png"

export default function TopBar() {
  let navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
    sessionStorage.removeItem('authKey');
    navigate('/login');
  };

  return (
    <>
      <Navbar className="shadow-sm" bg="dark" variant="dark">
        <Container>
          <Navbar.Brand className="ms-2 fw-bold" style={{ color: 'white' }}>
            <div>
              <Image 
              src={Logo} 
              width={50}
              />
            </div>
            {/* Nixauth-Client */}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav>
              <Nav.Link
                onClick={() => navigate('/dashboard')}
                className={location.pathname === '/dashboard' ? 'active-link fw-bold' : 'fw-bold'}
              >
                Dashboard
              </Nav.Link>
            </Nav>
            {GroupedRoutes.map((data: IGroupedRouter, index: number) => {
              if (!data.navbarShow) return null;
              return (
                <Nav key={index}>
                  <NavDropdown title={data.name} id="basic-nav-dropdown" className="fw-bold" menuVariant="dark">
                    {data.children.map((child: IRouter, idx: number) =>
                      !child.navbarShow ? null : (
                        <NavDropdown.Item
                          key={idx}
                          onClick={() => navigate(child.path)}
                          className={location.pathname.includes(child.path) ? 'active-link fw-bold' : ''}
                        >
                          {child.name}
                        </NavDropdown.Item>
                      )
                    )}
                  </NavDropdown>
                </Nav>
              );
            })}
          </Navbar.Collapse>

          <div className="d-flex justify-content-end ms-auto">
            <Button className="me-3" size="sm" onClick={handleLogout}>
              Logout
            </Button>
          </div>
        </Container>
      </Navbar>
    </>
  );
}
