import { faUser } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Pagination, Row, Spinner, Table } from 'react-bootstrap'
import toast from 'react-hot-toast';
import Select from "react-select"
import { UsersCard } from '../../component/Card/Statistics.card';
import { ClientService } from '../../component/services/client.service';
import CsrReportTable from '../../component/table/CsrReportTable';
import downloadjs from 'downloadjs';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import UserModal from '../../component/modals/User.modal';
import { FaRegEye } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { saveAsCSV } from '../../component/helpers/csvhelper';
import moment from 'moment';

export default function CenterCount() {

    const navigate = useNavigate();


    const [selectValue, setSelectValue] = useState<any>();
    const [shifts, setShifts] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const [centerData, setCenterData] = useState<any>([])

    const [zones, setZones] = useState<any>();
    const [districts, setDistricts] = useState<any>();
    const [centers, setCenters] = useState<any>();
    const [states, setStates] = useState<any>();


    const [selectedZones, setSelectedZones] = useState<any>();
    const [selectedDistricts, setSelectedDistricts] = useState<any>();
    const [selectedCenters, setSelectedCenters] = useState<any>();
    const [selectedStates, setSelectedStates] = useState<any>();

    const [shiftDate, setShiftDate] = useState<any>();
    const [selectedShiftDate, setSelectedShiftDate] = useState<any>()



    const getAllZones = async () => {
        await ClientService.getUniqueZone(selectValue).then(res => {
            if (res.status === 200) {
                setZones(res.data.map((zone: any) => {
                    return {
                        value: zone,
                        label: zone
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }


    const getUniqueCenter = async () => {
        let params = new URLSearchParams();
        params.append("zone[]", selectedZones?.value);
        params.append("state[]", selectedStates?.value);
        params.append("district[]", selectedDistricts?.value);

        await ClientService.getCenters(selectValue, "?" + params.toString()).then(res => {
            if (res.status === 200) {
                setCenters(res.data?.centers?.map((zone: any) => {
                    return {
                        value: zone,
                        label: zone
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }


    const getUniqueState = async () => {
        const params = new URLSearchParams();
        params.append("zone[]", selectedZones?.value);
        await ClientService.getStates(selectValue, "?" + params.toString()).then(res => {
            if (res.status === 200) {
                setStates(res.data.states?.map((zone: any) => {
                    return {
                        value: zone,
                        label: zone
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }


    const getUniqueCity = async () => {
        let params = new URLSearchParams();
        params.append("zone[]", selectedZones?.value);
        params.append("state[]", selectedStates?.value);
        await ClientService.getCities(selectValue, "?" + params.toString()).then(res => {
            if (res.status === 200) {
                setDistricts(res.data?.districts?.map((zone: any) => {
                    return {
                        value: zone,
                        label: zone
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }


    const getAllShift = async () => {
        await ClientService.getAllShifts().then(res => {
            if (res.status === 200) {
                setShifts(res.data.shifts.map((data: any) => {
                    return {
                        label: data.shiftName,
                        value: data._id
                    }
                }))
                setShiftDate(res.data.shifts.map((data: any) => {
                    return {
                        label: moment(data.startTime).format("DD MMM YYYY"),
                        value: data._id
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }

    const getCenterStats = async () => {
        setLoading(true)
        await ClientService.getCenterStats(selectValue, selectedCenters?.value, selectedZones?.value, selectedStates?.value, selectedDistricts?.value)
            .then((res) => {
                if (res.status === 200) {
                    setCenterData(res.data.centerStats);
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error(err.response?.data?.message || "An error occurred");
            });
        setLoading(false)
    };

    async function downloadCsv() {
        if (!centerData?.length) return toast.error("No data found");
        const csvData = [
            'Center,Zone,State,City,Enrolled,Verified',
            ...centerData.map((center: any) =>
                `${center.centerPreference},${center.zone},${center.state},${center.city},${center.totalUsers},${center.verifiedUsers}`
            ),
        ].join('\n');
        saveAsCSV(csvData, 'center-stats-data');
    }

    console.log(selectedStates, "selectedStates")

    useEffect(() => {
        if (selectValue) {
            getAllZones();
        }
    }, [selectValue]);

    useEffect(() => {
        if (selectedZones) {
            getUniqueState();
        } else {
            setStates([]);
            setSelectedStates(null);
        }
    }, [selectedZones]);

    useEffect(() => {
        if (selectedStates && selectedZones) {
            getUniqueCity();
        } else {
            setDistricts([]);
            setSelectedDistricts(null);
        }
    }, [selectedStates]);

    useEffect(() => {
        if (selectedStates && selectedZones && selectedDistricts) {
            getUniqueCenter();
        } else {
            setCenters([]);
            setSelectedCenters(null);
        }
    }, [selectedDistricts]);

    useEffect(() => {
        getAllShift();
    }, [])

    useEffect(() => {
        if (selectValue) {
            getCenterStats();
        }
    }, [selectedCenters, selectedDistricts, selectedStates, selectedZones, selectValue])

    return (
        <>
            <div className='d-flex justify-content-between align-items-center mt-3'>
                <h4 className="d-flex text-secondary mt-3">Center Stats</h4>
                <div className="d-flex">
                    <Select
                        options={shiftDate}
                        onChange={(e: any) => setSelectedShiftDate(e)}
                        placeholder="Select shift Date"
                        isClearable
                    />
                    <Select
                        options={
                            selectedShiftDate
                                ? shifts?.filter((shift: any) => shift.value === selectedShiftDate.value)
                                : []
                        }
                        onChange={(e: any) => setSelectValue(e?.value)}
                        placeholder="Select shift"
                        className="ms-3"
                        isClearable
                    />
                </div>
            </div>

            <Card
                className="mt-3"
                style={{
                    backgroundColor: "#f9f9f9",
                    borderRadius: "12px",
                    border: "none",
                }}
            >
                <Card.Body>
                    <Row>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label className="text-muted fw-bold">
                                    Divisions
                                </Form.Label>
                                <Select
                                    options={zones}
                                    onChange={(e: any) => setSelectedZones(e)}
                                    value={selectedZones}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    isClearable
                                />
                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label className="text-muted fw-bold">
                                    States
                                </Form.Label>
                                <Select
                                    options={states}
                                    onChange={(e: any) => setSelectedStates(e)}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    isClearable
                                    value={selectedStates}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label className="text-muted fw-bold">
                                    City
                                </Form.Label>
                                <Select
                                    options={districts}
                                    onChange={(e: any) => setSelectedDistricts(e)}
                                    value={selectedDistricts}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    isClearable
                                />
                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label className="text-muted fw-bold">
                                    Centers
                                </Form.Label>
                                <Select
                                    options={centers}
                                    onChange={(e: any) => setSelectedCenters(e)}
                                    value={selectedCenters}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    isClearable
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            {loading ? <Spinner animation="border" className="mt-4" /> :
                <Row>
                    <div className="d-flex align-items-center justify-content-end mb-2 mt-3">
                        {/* <h4 className="d-flex text-secondary">Center Stats</h4> */}
                        <Button className="ms-2" size="sm" onClick={downloadCsv}>Download</Button>
                    </div>
                    <Col>
                        <Card
                            className="mt-3"
                            style={{
                                backgroundColor: "#f9f9f9",
                                borderRadius: "12px",
                                border: "none",
                            }}
                        >
                            <Card.Body>
                                <Table striped hover responsive>
                                    <thead>
                                        <tr>
                                            <th style={{ fontSize: 14 }}>Sr. No</th>
                                            <th style={{ fontSize: 14 }}>Center Name</th>
                                            <th style={{ fontSize: 14 }}>State</th>
                                            <th style={{ fontSize: 14 }}>City</th>
                                            <th style={{ fontSize: 14 }}>Zone</th>
                                            <th style={{ fontSize: 14 }}>Enrolled Candidates</th>
                                            <th style={{ fontSize: 14 }}>Verified Candidates</th>
                                            <th style={{ fontSize: 14 }}>Verification (%)</th>
                                            <th style={{ fontSize: 14 }}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {centerData?.map((data: any, index: any) => {
                                            return (
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td style={{ fontSize: 12 }}>{data?.centerPreference}</td>
                                                    <td style={{ fontSize: 12 }}>{data?.state}</td>
                                                    <td style={{ fontSize: 12 }}>{data?.city}</td>
                                                    <td style={{ fontSize: 12 }}>{data?.zone}</td>
                                                    <td style={{ fontSize: 12 }}>{data?.totalUsers}</td>
                                                    <td style={{ fontSize: 12 }}>{data?.uniqueVerifiedUsers}</td>
                                                    <td style={{ fontSize: 12 }}>{data?.verificationPercentage}%</td>
                                                    <td style={{ fontSize: 12 }}><FaRegEye className='text-primary' onClick={() => navigate("/center-stats/" + selectValue + '/' + data?.centerPreference)} /> </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            }
        </>
    )
}