import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ClientService } from '../../component/services/client.service';
import { Card, Col, Row, Table } from 'react-bootstrap';
import { UsersCard } from '../../component/Card/Statistics.card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

export default function CenterStatsDetails() {

    const params = useParams();

    const [statsDetails, setStatsDetails] = useState<any>();

    const getCenterStatsDetails = async () => {
        await ClientService.getCenterStatsDetails(params.shiftId, params.centerId).then((res) => {
            if (res.status === 200) {
                setStatsDetails(res.data)
            }
        })
    }

    useEffect(() => {
        getCenterStatsDetails();
    }, [])


    return (
        <>
            <div className="pt-4">
                <h4 className="d-flex text-secondary">Center Details</h4>
                <div>
                    <Row>

                        <Col md={4}>
                            <UsersCard
                                reactIcon={
                                    <FontAwesomeIcon icon={faUser} className="fs-1 text-muted" />
                                }
                                header="Total"
                                statsValue={
                                    statsDetails?.totalUsers
                                        ? statsDetails?.totalUsers?.length
                                        : 0
                                }
                            />
                        </Col>
                        <Col md={4}>
                            <UsersCard
                                reactIcon={
                                    <FontAwesomeIcon icon={faUser} className="fs-1 text-muted" />
                                }
                                header="Verified"
                                statsValue={
                                    statsDetails?.uniqueVerifiedUsers
                                       
                                        || 0
                                }
                            />
                        </Col>
                        {/* <Col md={4}>
                            <UsersCard
                                reactIcon={
                                    <FontAwesomeIcon icon={faUser} className="fs-1 text-muted" />
                                }
                                header="Not-verified"
                                statsValue={
                                    statsDetails?.verifiedUsers && statsDetails?.totalUsers
                                        ? statsDetails?.totalUsers?.length -  statsDetails?.verifiedUsers?.length
                                        : 0
                                }
                            />
                        </Col> */}
                    </Row>

                </div>
                <Card className="shadow-sm mt-3 p-3 mb-3 bg-light rounded border-0">
                    <div className="d-flex flex-column gap-2">
                        <div className="d-flex align-items-center">
                            <strong className="text-secondary">Center Name:</strong>
                            <span className="ms-2 text-dark">{statsDetails?.centerPreference}</span>
                        </div>
                        <div className="d-flex align-items-center">
                            <strong className="text-secondary">State:</strong>
                            <span className="ms-2 text-dark">{statsDetails?.state}</span>
                        </div>
                        <div className="d-flex align-items-center">
                            <strong className="text-secondary">City:</strong>
                            <span className="ms-2 text-dark">{statsDetails?.city}</span>
                        </div>
                        <div className="d-flex align-items-center">
                            <strong className="text-secondary">Zone:</strong>
                            <span className="ms-2 text-dark">{statsDetails?.zone}</span>
                        </div>
                    </div>
                </Card>

                <h5 className="d-flex">Total Users</h5>
                <Card className="shadow-sm mt-3 p-3 mb-3 bg-light rounded border-0">
                    <Table striped hover responsive>
                        <thead>
                            <tr>
                                <th style={{fontSize: 14}}>Sr. No</th>
                                <th style={{fontSize: 14}}>Name</th>
                                <th style={{fontSize: 14}}>Email</th>
                                <th style={{fontSize: 14}}>Phone</th>
                                <th style={{fontSize: 14}}>Roll No</th>
                                <th style={{fontSize: 14}}>Registration Number</th>
                            </tr>
                        </thead>
                        <tbody>
                            {statsDetails?.totalUsers?.map((data: any, index: any) => {
                                return (
                                    <tr>
                                        <td style = {{fontSize: 12}}>{index + 1}</td>
                                        <td style = {{fontSize: 12}}>{data?.name || "--"}</td>
                                        <td style = {{fontSize: 12}}>{data?.email|| "--"}</td>
                                        <td style = {{fontSize: 12}}>{data?.mobileNo|| "--"}</td>
                                        <td style = {{fontSize: 12}}>{data?.rollNo|| "--"}</td>
                                        <td style = {{fontSize: 12}}>{data?.regNo|| "--"}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </Card>
                <h5 className="d-flex">Verified Users</h5>
                <Card className="shadow-sm mt-3 p-3 mb-3 bg-light rounded border-0">
                    <Table striped hover responsive>
                        <thead>
                        <tr>
                                <th style={{fontSize: 14}}>Sr. No</th>
                                <th style={{fontSize: 14}}>Name</th>
                                <th style={{fontSize: 14}}>Email</th>
                                <th style={{fontSize: 14}}>Phone</th>
                                <th style={{fontSize: 14}}>Roll No</th>
                                <th style={{fontSize: 14}}>Registration Number</th>
                            </tr>
                        </thead>
                        <tbody>
                            {statsDetails?.verifiedUsers?.map((data: any, index: any) => {
                                return (
                                    <tr>
                                        <td style = {{fontSize: 12}}>{index + 1}</td>
                                        <td style = {{fontSize: 12}}>{data?.name || "--"}</td>
                                        <td style = {{fontSize: 12}}>{data?.email|| "--"}</td>
                                        <td style = {{fontSize: 12}}>{data?.mobileNo|| "--"}</td>
                                        <td style = {{fontSize: 12}}>{data?.rollNo|| "--"}</td>
                                        <td style = {{fontSize: 12}}>{data?.regNo|| "--"}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </Card>

            </div>
        </>
    )
}
