import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row, Spinner, Tab, Tabs } from 'react-bootstrap';
import { UsersCard } from '../../component/Card/Statistics.card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faUser } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import { ClientService } from '../../component/services/client.service';
import toast from 'react-hot-toast';
import { makeParams } from '../../component/api/makeRequest';
import DataTable from '../../component/table/Invigilator.table';
import { saveAsCSV } from '../../component/helpers/csvhelper';
import moment from 'moment';

export default function InvigilatorStatsIndex() {
    const [statsData, setStatsData] = useState<any>(null);
    const [centers, setCenters] = useState<any>([]);
    const [selectedCenters, setSelectedCenters] = useState<any>([]);
    const [shifts, setShifts] = useState<any>([]);
    const [selectedShift, setSelectedShift] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [shiftDate, setShiftDate] = useState<any>([]);
    const [selectedShiftDate, setSelectedShiftDate] = useState<any>(null);

    const getUniqueCenter = async () => {
        try {
            const res = await ClientService.getUniqueCenter(selectedShift?.value);
            if (res.status === 200) {
                setCenters(
                    res.data.map((zone: any) => ({
                        value: zone,
                        label: zone,
                    }))
                );
            }
        } catch (err: any) {
            toast.error(err.response?.data || 'Failed to fetch centers');
        }
    };

    const getAllShift = async () => {
        setLoading(true);
        try {
            const res = await ClientService.getAllShifts();
            if (res.status === 200) {
                const shiftOptions = res.data.shifts.map((data: any) => ({
                    label: data.shiftName,
                    value: data._id,
                }));

                const shiftDates = res.data.shifts.map((data: any) => ({
                    label: moment(data.startTime).format('DD MMM YYYY'),
                    value: data._id,
                }));

                setShifts(shiftOptions);
                setShiftDate(shiftDates);
            }
        } catch (err: any) {
            toast.error(err.response?.data || 'Failed to fetch shifts');
        } finally {
            setLoading(false);
        }
    };

    const getStats = async () => {
        const payload = {
            centerId: selectedCenters?.map((data: any) => data?.value),
        }
        setLoading(true);
        try {
            const res = await ClientService.getInvigilatorStats(selectedShift?.value, payload);
            if (res.status === 200) {
                setStatsData(res.data);
            }
        } catch (err: any) {
            toast.error(err.response?.data || 'Failed to fetch stats');
        } finally {
            setLoading(false);
        }
    };

    const tabOptions = [
        { label: "Download Completed", value: "completedDownloadInvigilators", iconClass: "fs-1 text-success" },
        { label: "Download Pending", value: "pendingDownloadInvigilators", iconClass: "fs-1 text-warning" },
        { label: "Download In-progress", value: "inProgressDownloadInvigilators", iconClass: "fs-1 text-primary" },
        { label: "Logged-In Biometric Operators", value: "loggedInInvigilators", iconClass: "fs-1 text-success" },
        { label: "Total Biometric Operators", value: "totalInvigilators", iconClass: "fs-1" },
    ];

    const downloadCsv = (data: any[], fileName: string = 'data') => {
        if (!data?.length) {
            toast.error('No data found');
            return;
        }

        const csvData = [
            'Center,User Name,Email,Last Sync Time,Last Download Time,Download %,Upload %,Last Login Time,Last Logout Time',
            ...data.map((item: any) =>
                `${item?.centerId?.[0]},${item?.userName},${item?.emailId},${moment(item?.lastSyncedOn).format('DD-MM-YYYY hh:mm A')},${moment(item?.lastDownloadAt).format('DD-MM-YYYY hh:mm A')},${item?.meta.downloadStats.percentage},${item?.meta.uploadStats.percentage},${moment(item?.meta?.loginTimes?.slice(-1)?.[0]).format('DD-MM-YYYY hh:mm A')},${moment(item?.meta?.logoutTimes?.slice(-1)?.[0]).format('DD-MM-YYYY hh:mm A')}`
            ),
        ].join('\n');

        saveAsCSV(csvData, fileName);
    };

    useEffect(() => {
        if (selectedShift?.value) {
            getStats();
        }
    }, [selectedCenters, selectedShift]);

    useEffect(() => {
        if (selectedShift?.value) {
            getUniqueCenter();
        }
    }, [selectedShift]);

    useEffect(() => {
        getAllShift();
    }, []);

    return (
        <>
            {/* {loading ? (
                <div className="d-flex justify-content-center align-items-center card m-2 p-3">
                    <span className="fs-3">Loading...</span>
                    <Spinner animation="border" size="sm" />
                </div>
            ) : ( */}
            <>
                <div className="d-flex justify-content-between align-items-center mt-5">
                    <h4 className="text-secondary">Biometric Operators</h4>
                    <div className="d-flex">
                        <Select
                            options={shiftDate}
                            onChange={(e: any) => setSelectedShiftDate(e)}
                            value={selectedShiftDate}
                            placeholder="Select shift date"
                            isClearable
                        />
                        <Select
                            options={selectedShiftDate ? shifts?.filter((shift: any) => shift.value === selectedShiftDate.value) : []}
                            value={selectedShift}
                            onChange={(e: any) => setSelectedShift(e)}
                            placeholder="Select shift"
                            className="ms-3"
                            isClearable
                        />
                    </div>
                </div>
                <Card className="mt-3" style={{ backgroundColor: '#f9f9f9', borderRadius: '12px', border: 'none' }}>
                    <Card.Body>
                        <Row>
                            <Col md={3}>
                                <Form.Group>
                                    <Form.Label className="text-muted fw-bold">Centers</Form.Label>
                                    <Select
                                        options={[{ label: 'All', value: 'All' }, ...(centers || [])]}
                                        onChange={(selectedOptions: any) => {
                                            if (selectedOptions?.some((option: any) => option.value === 'All')) {
                                                setSelectedCenters(centers);
                                            } else {
                                                setSelectedCenters(selectedOptions || []);
                                            }
                                        }}
                                        value={selectedCenters}
                                        isClearable
                                        isMulti
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                {loading &&
                    <div className="d-flex justify-content-center align-items-center my-3">
                        <Spinner animation="border" />
                    </div>
                }
                <Row className="mt-4">
                    <Tabs defaultActiveKey={"completedDownloadInvigilators"}>
                        {tabOptions.map(({ label, value, iconClass }) => (
                            <Tab eventKey={value} title={label} key={value}>
                                <div className="mt-2">
                                    <UsersCard
                                        reactIcon={<FontAwesomeIcon icon={faUser} className={iconClass} />}
                                        header="Count"
                                        statsValue={statsData?.[value]?.count || 0}
                                    />
                                </div>
                                <div className="d-flex justify-content-end">
                                    <Button
                                        className="text-end"
                                        onClick={() => downloadCsv(statsData?.[value]?.invigilators, value)}
                                    >
                                        Download
                                    </Button>
                                </div>
                                <div>
                                    <DataTable data={statsData?.[value]?.invigilators} loading={loading} />
                                </div>
                            </Tab>
                        ))}
                    </Tabs>
                </Row>
            </>
            {/* )} */}
        </>
    );
}
