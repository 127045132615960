import React, { useEffect, useState } from 'react'
import { ClientService } from '../../component/services/client.service';
import { Card, Col, Row } from 'react-bootstrap';
import { UsersCard } from '../../component/Card/Statistics.card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faClock } from '@fortawesome/free-solid-svg-icons';

interface IAvarageTimingStats {
    shift: any,
    selectedCenters: any;
    selectedZones: any;
    selectedDistrict: any;
    selectedStates: any;
}

export default function AvarageTimingStats(props: IAvarageTimingStats) {

    const [averageTiming, setAverageTiming] = useState<any>();

    console.log(props.shift, "avarage ")

    const getAverageTiming = async () => {
        await ClientService.getCityStatsTime(props.shift).then((res) => {
            if (res.status === 200) {
                setAverageTiming(res.data.stats)
            }

        })
    }

    useEffect(() => {
        if (props.shift) {
            getAverageTiming();
        }
    }, [props.shift, props.selectedCenters, props.selectedZones, props.selectedDistrict, props.selectedStates]);
    return (
        <>
            <Row className="mt-3">
                <Col>
                    <Card
                        style={{
                            backgroundColor: "#f9f9f9",
                            borderRadius: "12px",
                            border: "none",
                        }}
                    >
                        <Card.Body>
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <div>
                                    <h6>City Wise Average Handling</h6>
                                </div>
                            </div>
                            <div className='d-flex flex-wrap gap-2 mt-3'>
                                {averageTiming?.map((data: any, index: number) => {
                                    return (
                                        <UsersCard
                                            key={index}
                                            reactIcon={
                                                <FontAwesomeIcon icon={faClock} className="fs-1 text-muted" />
                                            }
                                            header={data.city}
                                            statsValue={`${Math.floor(data.avgTimeInMin)} min` || 0}
                                        />
                                    )
                                })}
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}
